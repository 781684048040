<template>
<div></div>
</template>

<script>
import axios from "axios";
import qs from "qs";
import router from "@/router";
import AppConfig from "@/AppConfig";
import {systemErrorMsg} from "@/js/myNotification";
export default {
  name: "LoginVue2",
  async created() {
    let fullPath = this.$route.fullPath;//获取地址连接
    if(fullPath == null) {
      window.location.href = AppConfig.平台主系统地址;
    }
    if(fullPath.length <=0) {
      window.location.href = AppConfig.平台主系统地址;
    }
    ////console.log("fullPath="+fullPath)
    let url = fullPath.split("?")[1]
    if (url == null) {
      window.location.href = AppConfig.平台主系统地址;
    }
    if (url.length <=0) {
      window.location.href = AppConfig.平台主系统地址;
    }
    ////console.log("url="+url)
    let sid = url.split("=")[1];
    if(sid == null){
      window.location.href = AppConfig.平台主系统地址;
    }
    if(sid.length <=0){
      window.location.href = AppConfig.平台主系统地址;
    }
    await this.login(sid);
    //let selectYear = this.$route.params.sid;
    //await this.$route.push({ path: '/cc/所有 总得分'});
    //await this.login(selectYear);
  },
  data(){
    return{
      sid:"",
    }
  },
  methods: {
    login: function (selectYear) {
      let loading = this.$loading({text:"加载中..."});
      let data = {"sid": selectYear};
      axios.defaults.baseURL = AppConfig.登录服务器地址;//登陆使用登录服务器
      axios({
        method: "post",
        url: "/login2",
        data: qs.stringify(data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
        }
      })
          .then(
              function (response) {
                //this.rst = response;
                ////console.log(response);
                ////console.log(response.data.flag);
                ////console.log(response.data.msg);
                sessionStorage.clear();
                if (response.data.flag == true) {//登录成功
                  let obj = JSON.parse(response.data.msg);
                  let RSAPrivateKey2String = obj.RSAPrivateKey2String;
                  let RSAPublicKey1String = obj.RSAPublicKey1String;
                  let token = obj.token;
                  ////console.log("RSAPrivateKey2String=\n"+RSAPrivateKey2String);
                  ////console.log("RSAPublicKey1String=\n"+RSAPublicKey1String);
                  ////console.log("token=\n"+token);
                  sessionStorage.setItem("RSAPrivateKey2String", RSAPrivateKey2String);
                  sessionStorage.setItem("RSAPublicKey1String", RSAPublicKey1String);
                  sessionStorage.setItem("token", token);
                  sessionStorage.setItem("islogin", "ok");
                  sessionStorage.setItem("jobnumber", obj.jobnumber);
                  sessionStorage.setItem("name", obj.name);
                  sessionStorage.setItem("department", obj.department);
                  sessionStorage.setItem("limit", obj.limit);
                  sessionStorage.setItem("limitCollective", obj.limitCollective);
                  loading.close();
                  axios.defaults.baseURL = AppConfig.具体服务所在服务器地址;//登陆完，切换回来
                  router.push( AppConfig.公共基础路径 + "home/index");
                } else {
                  document.getElementById("rst").innerHTML = response.data.msg;
                  loading.close();
                  systemErrorMsg("登录失败"+response.data.msg);
                }
              })
          .catch(function (error) {
            loading.close();
            console.log(error);
          })
    },
  },
}
</script>

<style scoped>

</style>
