import {createRouter,  createWebHistory} from "vue-router";

import AppConfig from "@/AppConfig";
import HomeVue from "@/views/HomeVue";
import IndexVue from "@/views/Index/IndexVue";
import Login from "@/views/Login";
import LoginVue2 from "@/views/LoginVue2";
import LoginOut from "@/views/LoginOut";
import LimitVue from "@/views/limit/LimitVue.vue";
import PersonalVue from "@/views/personal/PersonalVue.vue";
import PersonalInfo from "@/views/personal/PersonalInfo.vue";
import PersonalSetting from "@/views/personal/PersonalSetting.vue";
import TestVue from "@/views/test/TestVue.vue";

//路由信息
const 基础路径=AppConfig.公共基础路径;// "/databasesadmin";
let routes = [
    {
        path:基础路径 + "",
        name: 'login',
        component:Login,
    },{
        path:基础路径+ "java",//"login/:sid",
        name:"login2",
        component:LoginVue2,
    },
    {
        path:基础路径+"quit",
        name:"quit",
        component:LoginOut
    },
    {
        path:基础路径+"home",
        component:HomeVue,
        children: [
            { name:"TestVue",path: 'TestVue', component: TestVue },
            { name:"index",path: 'index', component: IndexVue },
            { name:"limit",path: 'limit', component: LimitVue },
            {
                path:"personal",
                component:PersonalVue,
                children: [
                    { name:"PersonalInfo",path: 'PersonalInfo', component: PersonalInfo },
                    { name:"PersonalSetting",path: 'PersonalSetting', component: PersonalSetting },
                ],
            },
        ],
    },
];

//路由器
const router = createRouter({
    history:createWebHistory(),//html5模式
    // history:createWebHashHistory(),//html5模式
    //同一端口部署多项目，该项目打包到子目录添加以下内容，vue.config.js也要添加项
    //同一端口部署多项目，该项目打包到子目录添加以下内容，vue.config.js也要添加项
    base:process.env.NODE_ENV==='production'?基础路径:'',
    //同一端口部署多项目，该项目打包到子目录添加以上内容，vue.config.js也要添加项
    //同一端口部署多项目，该项目打包到子目录添加以上内容，vue.config.js也要添加项
    routes,
});

router.beforeEach((to, from,next) => {
    console.log("欢迎使用本系统。版权所有：" + AppConfig.页面底部版权单位名称);
    // console.log("路由守卫测试to");
    // console.log(to);
    // console.log(sessionStorage.getItem("token"));
    // console.log(sessionStorage.getItem("islogin"));
    //next();
    if(to.name === "login" || to.name === "login2"){
        // console.log("路由守卫测试next()");
        next();
    }else {
        let token = sessionStorage.getItem("token");
        if (token == null) {
            // console.log("路由守卫测试next({name: 'login'})");
            next({name: 'login'});
        } else {
            let allow = sessionStorage.getItem("islogin");
            if (allow == "ok") {
                // console.log("路由守卫测试allow=ok");
                // let limit=sessionStorage.getItem("limit");
                // if(limit == "超级管理员"){
                //     console.log("路由守卫测试next()1111");
                next();
                // }else{
                //     console.log("路由守卫测试next({name: 'login'})1111");
                //     next({name: 'login'});
                // }
            } else {
                // console.log("路由守卫测试next({name: 'login'})2222");
                next({name: 'login'});
            }
        }
    }
})
export default router;
