<template>
  <a-card class="card-demo" hoverable>
    <template #title>
      <span>{{ systemInfo.系统名称 }}</span>
    </template>
    <template #extra>
      <span>{{ systemInfo.系统子名称 }}</span>
    </template>
    <template #actions>
      <a-space wrap>
        <a-button type="primary" :status="cardbodyClass" size="small" @click="cardClick">
          <icon-unordered-list/>&nbsp;
          进入
        </a-button>
      </a-space>
    </template>
    <a-space direction="vertical" :size="10">
      <a-typography-text type="secondary">
        上级主管：{{ systemInfo.上级主管部门 }}
      </a-typography-text>
      <a-typography-text type="secondary">
        数据频度：{{ systemInfo.数据频度 }}
      </a-typography-text>
    </a-space>
  </a-card>
</template>

<script>
import {请求_参数不加密_响应不加密} from "@/request/请求处理";
import {响应接收_响应不加密} from "@/request/响应接收";
import {systemErrorMsg} from "@/js/myNotification";

export default {
  name: "systemInfoCard",
  props: {
    systemInfo: {
      type: Object
    },
  },
  // created() {
  //   console.log(this.systemInfo);
  // },
  async created() {
    let n = await random(1, 4);
    //console.log(n);
    if (n == 1) {
      this.cardbodyClass = "warning";
    } else if (n == 2) {
      this.cardbodyClass = "success";
    } else {
      this.cardbodyClass = "";
    }
  },
  data() {
    return {
      cardbodyClass: "",
    }
  },
  methods: {
    async cardClick() {
      if (await this.检查目标网站类型标记() == true) {
        //间接跳转，先写入sid,再带着sid值跳转
        let da = {
          dbn: 'a'
        }
        await 请求_参数不加密_响应不加密("/JumpJavaWeb", da);
        if (响应接收_响应不加密.flag == true) {
          window.location.href = this.systemInfo.跳转方法地址 + "?sid=" + 响应接收_响应不加密.msg;
        } else {
          systemErrorMsg("跳转前预置处理失败，暂无法跳转，请重试。");
        }
      } else {
        //直接跳转
        window.location.href = this.systemInfo.跳转方法地址;
      }
    },
    async 检查目标网站类型标记() {
      let flag = false;
      let n = this.systemInfo.跳转参数名数组.indexOf(",");
      if (n > -1) {
        let jump = this.systemInfo.跳转参数名数组.substring(0, n);
        if (jump != null) {
          if (jump == "jump") {
            flag = true;
          }
        }
      }
      return flag;
    },
  }
}
async function random(min, max) {

  return Math.floor(Math.random() * (max - min)) + min;

}
</script>

<style scoped>
.card-demo {
    width: 100%;
    transition-property: all;
    margin: 4px 4px 4px 4px;
}

.card-demo:hover {
    transform: translateY(-4px);
}

/*.grid-demo {*/
/*    background-color: var(--color-fill-2);*/
/*    !*margin: 20px 12px;*!*/
/*    width: 100vw !important;*/
/*}*/

/*.acol222 {*/
/*    text-align: right;*/
/*    margin-right: 12px;*/
/*    margin-top: 12px;*/
/*    margin-left : 12px;*/
/*}*/

/*.acol111 {*/
/*    margin-top: 12px;*/
/*}*/
</style>
