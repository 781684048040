<template>
<div>testvue</div>
  <a-button type="primary" @click="handleClick">Click me!</a-button>
</template>

<script>
export default {
  name: "TestVue",
  data() {
    return {
      list: [],
      data: {},
    };
  },
  methods: {
    handleClick() {
      this.$message.success("Click on success");
    }
  }
}
</script>

<style scoped>

</style>