/**
 *
 */
const clock = {
	timesum:8,
	计时器1:null,
	xtime:0,
	obj:null,
}
export function StartClock(xobj){//xobj为一个函数的引用
	clock.obj = xobj;
	clock.xtime=0;
	clock.计时器1=setInterval(myTimer, 1000);
}

export function StartClock_n(xobj,n){//xobj为一个函数的引用
	clock.obj = xobj;
	clock.xtime=0;
	clock.timesum = n;
	clock.计时器1=setInterval(myTimer, 1000);
}
function myTimer() {
	clock.xtime++;
	//console.log("计时器:"+clock.xtime);
	if (clock.xtime == clock.timesum) {
		clearInterval(clock.计时器1);
		clock.xtime=0;
		clock.obj();
	}
}



