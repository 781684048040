/*
文件名: myNotification.js
 */
import AppConfig from "@/AppConfig";
import { Notification } from '@arco-design/web-vue';
let xPosition = 'bottomRight';
export function systemInfoMsg(content) {
    Notification.info({
        title: AppConfig.页面左上角跟踪项目名称,
        content: content,
        position: xPosition
    })
}

export function systemErrorMsg(content) {
    Notification.error({
        title: AppConfig.页面左上角跟踪项目名称,
        content: content,
        position: xPosition,
        style: {backgroundColor: 'rgb(var(--orange-3))'}
    })
}

export function systemSuccessMsg(content) {
    Notification.success({
        title: AppConfig.页面左上角跟踪项目名称,
        content: content,
        position: xPosition
    })
}

export function systemWarningMsg(content) {
    Notification.warning({
        title: AppConfig.页面左上角跟踪项目名称,
        content: content,
        position: xPosition
    })
}
