<template>
  <div>PersonalVue</div>
  <router-view></router-view>
</template>

<script>
export default {
  name: "PersonalVue"
}
</script>

<style scoped>

</style>