import {请求实体_参数加密} from "@/request/请求实体";
import {响应接收_响应加密,响应接收_响应不加密} from "@/request/响应接收";
import router from "@/router";
import axios from "axios";
import qs from "qs";
// export default {
//     请求_参数加密_响应加密,请求_参数加密_响应不加密,请求_参数不加密_响应不加密,请求_参数不加密_响应加密
// }
export async function 请求_参数加密_响应加密(url,dataObject){
    await 请求实体_参数加密.init(dataObject);
    //await xxxx5(url,请求实体_参数加密.postQSdata);//post请求
    let token2 = sessionStorage.getItem("token");
    if (token2 == null) {
        router.push("/login");
    } else {
        let allow = sessionStorage.getItem("islogin");
        if (allow == "ok") {
            //console.log(data2);
            let aa = axios({
                method:"post",
                url:url,
                data:qs.stringify(请求实体_参数加密.postQSdata),
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token': sessionStorage.getItem('token'),//'your token',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });
            let bb;
            //console.log("request.js--第42行--xxxx3-->");
            await  aa.then(res=>{
                //console.log(res)
                bb=res;
            });
            //console.log("request.js--第62行--xxxx3-->");
            //console.log(bb);
            //sessionStorage.setItem("xxxx",JSON.stringify(bb.data));
            响应接收_响应加密.init(JSON.stringify(bb.data));
        } else {
            router.push("/login");
        }
    }
}
export async function 请求_参数加密_响应不加密(url,dataObject){
    await 请求实体_参数加密.init(dataObject);
    //执行请求
    //await xxxx5(url,请求实体_参数加密.postQSdata);//post请求
    let token2 = sessionStorage.getItem("token");
    if (token2 == null) {
        router.push("/login");
    } else {
        let allow = sessionStorage.getItem("islogin");
        if (allow == "ok") {
            //console.log(data2);
            let aa = axios({
                method:"post",
                url:url,
                data:qs.stringify(请求实体_参数加密.postQSdata),
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token': sessionStorage.getItem('token'),//'your token',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });
            let bb;
            //console.log("request.js--第42行--xxxx3-->");
            await  aa.then(res=>{
                //console.log(res)
                bb=res;
            });
            //console.log("request.js--第62行--xxxx3-->");
            //console.log(bb);
            //sessionStorage.setItem("xxxx",JSON.stringify(bb.data));
            响应接收_响应不加密.init(JSON.stringify(bb.data));
        } else {
            router.push("/login");
        }
    }
}
export async function 请求_参数不加密_响应不加密(url,dataObject){
    // console.log("请求_参数不加密_响应不加密--11111--->");
    // console.log(dataObject);
    //await 请求实体_参数不加密.init(dataObject);
    //执行请求
    //await xxxx5(url,请求实体_参数不加密.postQSdata);//post请求
    let token2 = sessionStorage.getItem("token");
    if (token2 == null) {
        router.push("/login");
    } else {
        let allow = sessionStorage.getItem("islogin");
        if (allow == "ok") {
            // console.log(请求实体_参数不加密.postQSdata);
            //console.log(data2);
            let aa = axios({
                method:"post",
                url:url,
                data:qs.stringify(dataObject),//参数不加密，可以直接处理
                // data:qs.stringify(请求实体_参数不加密.postQSdata),
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token': sessionStorage.getItem('token'),//'your token',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });
            let bb;
            //console.log("request.js--第42行--xxxx3-->");
            await  aa.then(res=>{
                //console.log(res)
                bb=res;
            });
            //console.log("request.js--第62行--xxxx3-->");
            //console.log(bb);
            //sessionStorage.setItem("xxxx",JSON.stringify(bb.data));
            响应接收_响应不加密.init(JSON.stringify(bb.data));
        } else {
            router.push("/login");
        }
    }
}
export async function 请求_参数不加密_响应加密(url,dataObject){
    //await 请求实体_参数不加密.init(dataObject);
    //执行请求
    //await xxxx5(url,请求实体_参数不加密.postQSdata);//post请求
    let token2 = sessionStorage.getItem("token");
    if (token2 == null) {
        router.push("/login");
    } else {
        let allow = sessionStorage.getItem("islogin");
        if (allow == "ok") {
            //console.log(data2);
            let aa = axios({
                method:"post",
                url:url,
                data:qs.stringify(dataObject),//参数不加密，可以直接处理
                //data:qs.stringify(请求实体_参数不加密.postQSdata),
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'token': sessionStorage.getItem('token'),//'your token',
                    'X-Requested-With': 'XMLHttpRequest',
                }
            });
            let bb;
            //console.log("request.js--第42行--xxxx3-->");
            await  aa.then(res=>{
                //console.log(res)
                bb=res;
            });
            //console.log("request.js--第62行--xxxx3-->");
            //console.log(bb);
            //sessionStorage.setItem("xxxx",JSON.stringify(bb.data));
            响应接收_响应加密.init(JSON.stringify(bb.data));
        } else {
            router.push("/login");
        }
    }
}