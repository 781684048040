<template>
  <div>PersonalSetting</div>
</template>

<script>
export default {
  name: "PersonalSetting"
}
</script>

<style scoped>

</style>