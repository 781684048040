<template>
<a-row v-if="xDisplay">
  <a-col v-for="(item,index) in displayMsg" :key="index">
    <a-icon type="close-circle" style="color: red;"/>
    <span style="color: red;">{{item}}</span>
  </a-col>
</a-row>
</template>

<script>
export default {
  name: "GetDataError",
  props: {
    msg: {
      type: Array,
      default: () => [],
    },
    flag: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    flag: function (newVal) {
      this.xDisplay = newVal;
      if(this.xDisplay == true){
        if(this.msg != null){
          if(this.msg.length > 0){
            this.displayMsg = this.msg;
          }
        }
      }
    },
  },
  data() {
    return {
      displayMsg: [
        "从数据库中获取数据失败,可能是以下原因：",
        "1、您还未连接网络",
        "2、是否系统后台设置的服务器地址有误，或者服务器已关闭，此情况需要联系管理员解决。",
      ],
      xDisplay:false,
    };
  },
}
</script>

<style scoped>

</style>
