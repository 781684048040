<template>
  <a-button size="small"  @click="changeTheme" v-show="theme">
    <icon-sun-fill/></a-button>
  <a-button size="small"  @click="changeTheme" v-show="!theme">
    <icon-moon-fill/></a-button>
</template>

<script>
export default {
  name: "ArcoTheme",
  data(){
    return {
      theme: true,
    };
  },
  methods: {
    changeTheme(){
      if(this.theme) {
        // 设置为暗黑主题
        document.body.setAttribute('arco-theme', 'dark');
        this.theme = false;
      } else {// 恢复亮色主题
        document.body.removeAttribute('arco-theme');
        this.theme = true;
      }
    }
  },
}
</script>

<style>
body {
  background-color: var(--color-neutral-2);
}
body[arco-theme='dark'] {
  --color-fcfcfc: rgba(255, 255, 255, 0.02);
  background-color: var(--color-neutral-2);
}
</style>