<template>
  <a-affix>
    <div :style="{ background: 'var(--color-fill-2)', padding: '8px' }" >
    <a-page-header
        :style="{ background: 'var(--color-bg-2)' }"
    >
    <!--        title="ArcoDesign"-->
    <!--        subtitle="ArcoDesign Vue 2.0"-->
      <template #title>
        {{ x平台名称 }}
      </template>
      <template #subtitle>
        {{ x子系统名称 }}
      </template>

      <template #back-icon>
        <icon-drag-dot />
      </template>

<!--      <template #breadcrumb>-->
<!--        <a-space>-->
<!--          <a-button type="primary">Primary</a-button>-->
<!--          <a-button>Secondary</a-button>-->
<!--        </a-space>-->
<!--      </template>-->

      <template #extra>
        <a-space>
          <a-button >
            <template #icon>
              <icon-home />
            </template>
            <template #default>{{ x部门名称 }}</template>
          </a-button>
          <a-dropdown trigger="hover">
            <a-button>
              <template #icon>
                <icon-user />
              </template>
              <template #default>{{ x姓名 }}</template>
            </a-button>
            <template #content>
              <a-doption @click="mPersonalInfo">个人信息</a-doption>
              <a-doption @click="mPersonalSetting">设置</a-doption>
            </template>
          </a-dropdown>
          <ArcoTheme />
          <a-dropdown trigger="hover">
            <a-button>
              <template #icon>
                <icon-language />
              </template>
              <template #default>{{ x已选语言 }}</template>
            </a-button>
            <template #content>
              <a-doption @click="mSwitchLanguage('简体中文')">简体中文</a-doption>
              <a-doption @click="mSwitchLanguage('繁体中文')">繁体中文</a-doption>
              <a-doption @click="mSwitchLanguage('English')">English</a-doption>
            </template>
          </a-dropdown>
          <a-dropdown trigger="hover">
            <a-button>
              <template #icon>
                <icon-list />
              </template>
            </a-button>
            <template #content>
              <a-doption @click="returnHome">返回首页</a-doption>
<!--              <a-doption @click="returnMainSystem">返回主系统</a-doption>-->
              <a-doption @click="GotoLimit">访问权限管理</a-doption>
              <a-doption @click="TestVue">测试</a-doption>
            </template>
          </a-dropdown>
          <a-tooltip content="退出系统">
            <a-button @click="quit">
              <template #icon>
                <icon-poweroff />
              </template>
            </a-button>
          </a-tooltip>
        </a-space>
      </template>
    </a-page-header>
  </div>
  </a-affix>

</template>

<script>
import ArcoTheme from "@/components/global/ArcoTheme";
import AppConfig from "@/AppConfig";

export default {
  name: "titleVue",
  components: {
    ArcoTheme,
  },
  data(){
    return {
      x部门名称: sessionStorage.getItem("department"),
      x姓名: sessionStorage.getItem("name"),
      x平台名称: AppConfig.页面左上角平台名称,
      x子系统名称: AppConfig.已选择子系统名称,
      x已选语言: "简体中文",
    };
  },
  methods: {
    mSwitchLanguage(lang){
      this.$emit("mSwitchLanguage", lang);
      this.x已选语言 = lang;
      // console.log("titleVue：", lang);
    },
    returnHome(){
      this.$router.push({ name:'index'})
    },
    quit(){
      this.$router.push({ name:"quit"});
    },
    returnMainSystem(){
      window.location.href = AppConfig.平台主系统地址;
    },
    GotoLimit(){
      this.$router.push({ name:'limit'})
    },
    mPersonalInfo(){
      this.$router.push({ name:'PersonalInfo'})
    },
    mPersonalSetting(){
      this.$router.push({ name:'PersonalSetting'})
    },
    TestVue(){
      this.$router.push({ name:'TestVue'})
    },
  },
}
</script>

<style scoped>
</style>
