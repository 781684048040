<template>
  <a-row v-show="loading">
    <a-col :span="24" style="text-align: center"><a-spin :loading="loading" tip="数据加载中..."></a-spin></a-col>
  </a-row>
</template>

<script>
export default {
  name: "LoadingVue",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>