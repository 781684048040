
import JSEncrypt from "jsencrypt";
//import jse from '../components/暂时没用的/rsa_s'

export {
    响应接收_响应加密,响应接收_响应不加密
};
let 响应接收_响应加密 = {
    flag: null,
    msg: null,
    id: null,
    arr:null,
    arrLength:0,
    //code:null,
    init: function (xlstr) {
        var obj = JSON.parse(xlstr);
        this.flag = obj.flag;
        this.msg = obj.msg;
        this.id = obj.id;
        this.arrLength = obj.arrLength;
        if(this.arrLength>0){
            this.arr = new Array();
            for(let i=0;i<obj.arr.length;i++){
                this.arr[this.arr.length] = obj.arr[i];
            }
        }
        if(this.flag == true){
            //this.msg = this.解密(this.msg);
            if(this.arrLength>0){
                this.msg="";
                let temp;
                //console.log("位置：ReturnMessage.js第45行");
                for(let i=0;i<this.arr.length;i++){
                    temp = this.解密(this.arr[i]);
                    //console.log(this.arr[i]);
                    //console.log(temp);
                    this.msg+=temp;
                }
            }else{
                //this.msg = this.解密(this.msg);
            }
            //console.log("位置：ReturnMessage.js第53行");
            //console.log(this.msg);
        }
    },
    解密:function(rst){
        return 使用私钥解密(rst);
    },
    clearMsg:function(){
        this.msg=null;
    }
};
//私钥
let PRIVATE_KEY = 'MIICWwIBAAKBgQCh5Nk2GLiyQFMIU+h3OEA4UeFbu3dCH5sjd/sLTxxvwjXq7JLqJbt2rCIdzpAXOi4jL+FRGQnHaxUlHUBZsojnCcHvhrz2knV6rXNogt0emL7f7ZMRo8IsQGV8mlKIC9xLnlOQQdRNUssmrROrCG99wpTRRNZjOmLvkcoXdeuaCQIDAQABAoGAUTcJ1H6QYTOts9bMHsrERLymzir8R9qtLBzrfp/gRxxpigHGLdph8cWmk8dlN5HDRXmmkdV6t2S7xdOnzZen31lcWe0bIzg0SrFiUEOtg3Lwxzw2Pz0dKwg4ZUooGKpcIU6kEpbC2UkjBV4+2E6P1DXuhdgTyHoUA3ycxOdjCAUCQQCyjTzGPXFoHq5TmiJyVd4VXNyCXGU0ZuQayt6nPN8Gd5CcEb2S4kggzPXQcd90FO0kHfZV6+PGTrc2ZUuz5uwPAkEA6B3lmEmiZsJS/decLzWR0T1CXaFGwTjBQbHXJ0RziAfkuy+VwSmhvrW/ipk5xbREr5rKx3jVI2PzVOvLw7NgZwJAbUsvDFnH9WfyZZJPy5TsID97awCLoovozM2phM0p55eAmUfyttp0ND/BqBpMIY49qoH8q5N9FYJRe6Z9tF2B2QJAQBEocw039xcB4zCk2l713YQEEmXWarSomuJkWWFKZiyPlJ8Ava0pCMOPl8jNKmWkY7fc6ovOgJMw8aqXtm+HVwJAerJeUEDez2djG5pIF6aCV0bP3fhQUq8OQCgGF5Qzo9CnqvYreGpYKPJGVixAsEPCiLzJRhy1XfFona6VRXIIxw==';

function 使用私钥解密(encrypted) {
    //使用私钥解密
    PRIVATE_KEY = sessionStorage.getItem("RSAPrivateKey2String");
    var decrypt = new JSEncrypt();
    //decrypt.setPublicKey('-----BEGIN PUBLIC KEY-----' + PUBLIC_KEY + '-----END PUBLIC KEY-----');
    decrypt.setPrivateKey('-----BEGIN RSA PRIVATE KEY-----' + PRIVATE_KEY + '-----END RSA PRIVATE KEY-----');
    var uncrypted = decrypt.decrypt(encrypted);
    //console.log("位置：ReturnMessage.js第46行");
    //console.log('解密后数据:%o', uncrypted);
    return uncrypted;
}
let 响应接收_响应不加密 = {
    flag: null,
    msg: null,
    id: null,
    arr:null,
    arrLength:0,
    //code:null,
    init: function (xlstr) {
        var obj = JSON.parse(xlstr);
        this.flag = obj.flag;
        this.msg = obj.msg;
        this.id = obj.id;
    },
    clearMsg:function(){
        this.msg=null;
    }
};
