<template>
<div></div>
</template>

<script>
import {请求_参数不加密_响应不加密} from "@/request/请求处理";
import AppConfig from "@/AppConfig";
export default {
  name: "LoginOut",
  async created() {
    sessionStorage.removeItem("RSAPrivateKey2String");
    sessionStorage.removeItem("RSAPublicKey1String");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("islogin");
    sessionStorage.removeItem("jobnumber");
    sessionStorage.removeItem("name");
    sessionStorage.removeItem("department");
    sessionStorage.removeItem("limit");
    sessionStorage.removeItem("limitCollective");
    退出();
    window.location.href = AppConfig.公共基础路径;
  },
}
async function 退出() {
  let da = {
    timer: "quit"
  }
  await 请求_参数不加密_响应不加密("/quit", da);
  //let aa = JSON.parse(ReturnMessage.msg);
  // //console.log("位置：LoginOut.vue第28行");
  // //console.log(ReturnMessage.msg);
}
</script>

<style scoped>

</style>
