import JSEncrypt from "jsencrypt";

export {
    请求实体_参数加密,
    请求实体_参数不加密
};
let 请求实体_参数加密 = {
    token: null,
    rst : null,
    postParamsData:null,
    postQSdata:null,
    init: function (data1) {
        let aa="";
        for(let key in data1){
            aa += "&" + key +"=" + this.编码(this.加密(data1[key]));
        }
        this.rst = "?" + aa.substring(1,aa.length);//get请求的参数
        this.postParams(data1);//post请求的参数
        this.postQSdataFunction(data1);//post请求的参数,qs.qs.stringify转化用
    },
    postParams:function(data1){
        this.postParamsData = new URLSearchParams();
        for(let key in data1){
            this.postParamsData.append(key,this.加密(data1[key]));
        }
    },
    postQSdataFunction:function(data1){
        let str;
        for(let key in data1){
            str=data1[key];
            data1[key]=this.加密(str);
        }
        this.postQSdata=data1;
    },
    加密: function(str){
        return 使用公钥加密(str);
    },
    编码:function(str){
        return encodeURIComponent(str);
    }
}


let 请求实体_参数不加密 = {
    token: null,
    rst : null,
    postParamsData:null,
    postQSdata:null,
    init: async function (data1) {
        //this.token = token1;
        let aa="";
        //console.log("entity.js--第9行--->RequestEntity-->");
        for(let key in data1){
            //    console.log(data1[key]);
           aa += "&" + key +"=" + this.编码(data1[key]);
        }
        this.rst = "?" + aa.substring(1,aa.length);//get请求的参数
        await this.postParams(data1);//post请求的参数
        await this.postQSdataFunction(data1);//post请求的参数,qs.qs.stringify转化用
    },
    postParams:async function(data1){
        this.postParamsData =await new URLSearchParams();
        for(let key in data1){
            await this.postParamsData.append(key,data1[key]);
        }
    },
    postQSdataFunction:async function(data1){
        let str;
        for(let key in data1){
            str=data1[key];
            data1[key]=str;
        }
        this.postQSdata=data1;
    },
    编码:async function(str){
        return encodeURIComponent(str);
    },
    clear:async function(){
        this.token = null;
        this.rst = null;
        this.postParamsData=null;
        this.postQSdata=null;
    }
}


//公钥
let PUBLIC_KEY = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCh5Nk2GLiyQFMIU+h3OEA4UeFbu3dCH5sjd/sLTxxvwjXq7JLqJbt2rCIdzpAXOi4jL+FRGQnHaxUlHUBZsojnCcHvhrz2knV6rXNogt0emL7f7ZMRo8IsQGV8mlKIC9xLnlOQQdRNUssmrROrCG99wpTRRNZjOmLvkcoXdeuaCQIDAQAB';
//私钥
//let PRIVATE_KEY = 'MIICWwIBAAKBgQCh5Nk2GLiyQFMIU+h3OEA4UeFbu3dCH5sjd/sLTxxvwjXq7JLqJbt2rCIdzpAXOi4jL+FRGQnHaxUlHUBZsojnCcHvhrz2knV6rXNogt0emL7f7ZMRo8IsQGV8mlKIC9xLnlOQQdRNUssmrROrCG99wpTRRNZjOmLvkcoXdeuaCQIDAQABAoGAUTcJ1H6QYTOts9bMHsrERLymzir8R9qtLBzrfp/gRxxpigHGLdph8cWmk8dlN5HDRXmmkdV6t2S7xdOnzZen31lcWe0bIzg0SrFiUEOtg3Lwxzw2Pz0dKwg4ZUooGKpcIU6kEpbC2UkjBV4+2E6P1DXuhdgTyHoUA3ycxOdjCAUCQQCyjTzGPXFoHq5TmiJyVd4VXNyCXGU0ZuQayt6nPN8Gd5CcEb2S4kggzPXQcd90FO0kHfZV6+PGTrc2ZUuz5uwPAkEA6B3lmEmiZsJS/decLzWR0T1CXaFGwTjBQbHXJ0RziAfkuy+VwSmhvrW/ipk5xbREr5rKx3jVI2PzVOvLw7NgZwJAbUsvDFnH9WfyZZJPy5TsID97awCLoovozM2phM0p55eAmUfyttp0ND/BqBpMIY49qoH8q5N9FYJRe6Z9tF2B2QJAQBEocw039xcB4zCk2l713YQEEmXWarSomuJkWWFKZiyPlJ8Ava0pCMOPl8jNKmWkY7fc6ovOgJMw8aqXtm+HVwJAerJeUEDez2djG5pIF6aCV0bP3fhQUq8OQCgGF5Qzo9CnqvYreGpYKPJGVixAsEPCiLzJRhy1XfFona6VRXIIxw==';

function 使用公钥加密(str) {
    //使用公钥加密
    PUBLIC_KEY = sessionStorage.getItem("RSAPublicKey1String");
    var encrypt = new JSEncrypt();
    encrypt.setPublicKey(PUBLIC_KEY);
    var encrypted = encrypt.encrypt(str);//(JSON.stringify(str));
    return encrypted;
}