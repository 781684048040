<template>
  <a-config-provider :locale="x选择语言">
    <div v-show="!xlinkShow">
      <titleVue ref="titleVue" @mSwitchLanguage="mSwitchLanguage"/>
<!--      <title-menu ref="titleMenu" @mSwitchLanguage="mSwitchLanguage"/>-->
    </div>
    <div v-show="xlinkShow">
      <title-menu ref="titleMenu" @mSwitchLanguage="mSwitchLanguage"/>
    </div>
    <a-row class="homerow">
      <a-col :span="24">
        <router-view></router-view>
      </a-col>
    </a-row>
    <a-back-top/>
    <footVue />
  </a-config-provider>
</template>

<script>
import titleVue from "@/components/global/titleVue";
import footVue from "@/components/global/footVue";

import enUS from '@arco-design/web-vue/es/locale/lang/en-us';
import zhCN from '@arco-design/web-vue/es/locale/lang/zh-cn';
import zhTW from '@arco-design/web-vue/es/locale/lang/zh-tw';
import titleMenu from "@/components/global/titleMenu.vue";
export default {
  name: "HomeVue",
  components: {
    titleVue,footVue,titleMenu
  },
  mounted() {
    this.screenWidth = document.body.clientWidth;
    this.xlinkShow=this.m是否缩放(this.screenWidth);
    window.addEventListener('resize', () => {
      this.screenWidth = document.body.clientWidth;
      this.xlinkShow=this.m是否缩放(this.screenWidth);
      // if (this.screenWidth < 1200) {
      //   this.xlinkShow = true;
      // } else {
      //   this.xlinkShow = false;
      // }
    });
  },
  data(){
    return {
      x选择语言: zhCN,
      screenWidth:null,
      xlinkShow: false,
    }
  },
  methods: {
    m是否缩放(w){
      if (w < 1200) {
        return  true;
      } else {
        return  false;
      }
    },
    mSwitchLanguage(lang) {
      // console.log("HomeVue：", lang);
      if(lang == "简体中文") {
        this.x选择语言 = zhCN;
      } else if(lang == "繁体中文") {
        this.x选择语言 = zhTW;
      } else if(lang == "English") {
        this.x选择语言 = enUS;
      }
    },
  },
}
</script>

<style scoped>
.homerow{
  padding: 8px;
}
</style>
