import { createApp } from 'vue'
import App from './App.vue';
import ArcoVue from '@arco-design/web-vue';
import ArcoVueIcon from '@arco-design/web-vue/es/icon';
import '@arco-design/web-vue/dist/arco.css';
import router from "@/router";
import AppConfig from "@/AppConfig";
import axios from 'axios'
import VueAxios from 'vue-axios'
import * as XLSX from 'xlsx/xlsx.mjs'
const app = createApp(App);
app.use(ArcoVue);
app.use(ArcoVueIcon);
app.use(ArcoVue, {
    // 用于改变使用组件时的前缀名称
    componentPrefix: 'arco'
});
app.use(XLSX)
app.use(AppConfig)
app.use(router)

//除了在一个组件中提供依赖，我们还可以在整个应用层面提供依赖。这样我们就可以在任何组件中使用这些依赖了。
// 在 main.js 中提供依赖
// app.provide('inject已选时点', '')
// app.provide('inject已选表名', '')
// app.config.unwrapInjectedRef = true
//除了在一个组件中提供依赖，我们还可以在整个应用层面提供依赖。这样我们就可以在任何组件中使用这些依赖了。

app.mount('#app');
app.use(VueAxios, axios)

