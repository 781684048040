<template>
<a-row align="center" justify="center" class="myArco-row">
  <a-col :lg="6" :xs="20" class="myArcoColLogin">
    <a-row align="center" justify="center">
      <a-col class="myArco-col">
        <a-typography>
          <a-typography-title :heading="4">
            {{ x平台名称 }}
          </a-typography-title>
        </a-typography>
      </a-col>
    </a-row>

    <a-row align="center" justify="center">
      <a-col class="myArco-col" :span="24">
        <a-space>
          <a-button size="small" @click="切换登录方式(true)">用户名登录</a-button>
          <a-button size="small" @click="切换登录方式(false)">手机号登录</a-button>
        </a-space>
      </a-col>
    </a-row>

    <a-row align="center" justify="center"><a-col><br /></a-col></a-row>

    <a-row align="center" justify="center">
      <a-col :span="20">
        <a-row>
          <a-col>
            <span style="font-weight: bold;">{{ usernameLabel }}</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-input v-model="username"/>
          </a-col>
        </a-row>
        <a-row align="center" justify="center"><a-col><br /></a-col></a-row>

        <a-row>
          <a-col>
            <span style="font-weight: bold;">{{ passwordLabel }}</span>
          </a-col>
        </a-row>
        <a-row>
          <a-col>
            <a-input v-model="password" v-show="登录方式" type="password" placeholder="请输入密码" show-password>
            </a-input>
            <a-input v-model="password" v-show="!登录方式" type="password" placeholder="请输入动态密码（区分大小写）" show-password
                       @change="refreshCode">
              <template #append>
                <a-button type="primary" text @click="getPassword" v-show="!登录方式"  :disabled="getPasswordButtonShow" style="font-weight: bold;">{{获取验证码}}</a-button>
              </template>
            </a-input>
          </a-col>
        </a-row>
        <a-row align="center" justify="center" v-show="!登录方式"><a-col><br /></a-col></a-row>
        <a-row v-show="!登录方式">
          <a-col>
            <span style="font-weight: bold;">图形验证码</span>
          </a-col>
          <a-col>
            <a-input v-model="图形验证码" placeholder="请输入图形验证码（区分大小写）" @keyup="图形验证码校验">
            </a-input>
          </a-col>
        </a-row>
        <a-row align="center" justify="center" v-show="!登录方式"><a-col><br /></a-col></a-row>
        <a-row v-show="!登录方式">
          <a-col style="font-weight: bold;">点击刷新：</a-col>
        </a-row>
        <a-row v-show="!登录方式">
          <a-col flex="auto">
            <div class="get-code" @click="refreshCode()">
              <SIdentify :identifyCode="identifyCode"></SIdentify>
            </div>
          </a-col>
          <a-col flex="auto">
            <div v-show="图形验证码校验flag22">
              <a-image v-show="图形验证码校验flag" width="40" height="40" :src="xpic_cc" fit="contain"/>
              <a-image v-show="!图形验证码校验flag" width="40" height="40" :src="xpic_ee" fit="contain"/>
            </div>
          </a-col>
          <a-col  flex="auto">
            <div v-show="图形验证码校验flag22">
              <span v-show="图形验证码校验flag" style="color:#67C23A;font-weight: bold;font-size: 24px;">正确</span>
              <span v-show="!图形验证码校验flag" style="color:#F56C6C;font-weight: bold;font-size: 24px;">错误</span>
            </div>
          </a-col>
        </a-row>
        <a-row align="center" justify="center"><a-col><br /></a-col></a-row>
        <a-row justify="center">
          <a-button type="primary" v-on:click="loginStart" long>登陆</a-button>
        </a-row>
      </a-col>
    </a-row>

    <a-row align="center" justify="center">
      <a-col class="myArco-col">
        <span id="rst" style="color: rgb(245, 108, 108);"></span>
      </a-col>
    </a-row>
    <a-row align="center" justify="center"><a-col><br /><br /><br /></a-col></a-row>
    <a-row align="center" justify="center" class="loginrow1">
      <a-col class="myArco-col">
        &copy; 2021-{{ time }} {{ x版权单位 }}
      </a-col>
    </a-row>
    <a-row align="center" justify="center"><a-col><br /></a-col></a-row>
    <a-row align="center" justify="center">
      <a-col class="myArco-col">
        <a-link type="info" href="https://beian.miit.gov.cn/" target="_blank">{{ icp备案号 }}</a-link>
      </a-col>
    </a-row>
    <a-row align="center" justify="center"><a-col><br /></a-col></a-row>
  </a-col>
</a-row>
</template>

<script>
/*
 */
import axios from "axios";
import router from "@/router";
import qs from "qs";
import {StartClock_n} from "@/js/clock"
import AppConfig from "@/AppConfig"
import {请求实体_参数加密} from "@/request/请求实体";
import pic_ee from "@/assets/ee.png"
import pic_cc from "@/assets/cc.png"
import SIdentify from "@/views/sIdentify";
import {systemErrorMsg, systemSuccessMsg} from "@/js/myNotification";

const k = 60;
export default {
  name: "LoginControl",
  async created() {
    let token = sessionStorage.getItem("token");
    let allow = sessionStorage.getItem("islogin");
    if (token != null) {
      if (allow == "ok") {
        axios.defaults.baseURL = AppConfig.具体服务所在服务器地址;//登陆完，切换回来
        // console.log("登陆完，切换回来");
        await router.push(AppConfig.公共基础路径 + "home/index");
      }
    }
  },
  components: {
    SIdentify,
  },
  data() {
    return {
      // loginTypeActive:1,
      x平台名称: "U D C P E P",
      x版权单位: "UDCPEP",
      icp备案号: AppConfig.icp备案号,
      xpic_cc: pic_cc,
      xpic_ee: pic_ee,
      username: "",
      password: "",
      time: gettime(),
      getPasswordButtonShow: false,
      获取验证码: "获取动态密码",
      n: k,
      计时器: null,
      identifyCode: "",
      identifyCodes: "0123456789abcdwerwshdjeJKDHRJHKOOPLMKQ",//随便打的
      图形验证码: "",
      图形验证码校验flag: false,
      图形验证码校验flag22: false,
      xstyle: "",
      登录方式: false,
      usernameLabel: "手机号",
      passwordLabel: "动态密码",
    }
  },
  watch: {
    登录方式() {
      if (this.登录方式 == false) {//手机号登录
        this.usernameLabel = "手机号";
        this.passwordLabel = "动态密码";
        this.refreshCode();
      } else {//用户名和密码登录
        this.usernameLabel = "用户名";
        this.passwordLabel = "密码";
      }
    }
  },
  methods: {
    切换登录方式(flag) {
      this.登录方式 = flag;
    },
    图形验证码校验() {
      if (this.图形验证码 == "") {
        this.图形验证码校验flag22 = false;
      } else {
        this.图形验证码校验flag22 = true;
        if (this.identifyCode == this.图形验证码) {
          this.图形验证码校验flag = true;
          this.xstyle = "color:#67C23A;";
        } else {
          this.图形验证码校验flag = false;
          this.xstyle = "color:#F56C6C;";
        }
      }
    },
    //====================
    refreshCode() {//
      this.identifyCode = "";
      this.图形验证码 = "";
      this.图形验证码校验flag22 = false;
      this.makeCode(this.identifyCodes, 4);
    },
    randomNum(min, max) {
      max = max + 1
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 随机生成验证码字符串
    makeCode(data, len) {
      for (let i = 0; i < len; i++) {
        this.identifyCode += data[this.randomNum(0, data.length - 1)]
      }
    },
    //====================
    async getPassword() {
      this.获取验证码 = "重发动态密码(" + String(this.n) + ")";
      this.计时器 = setInterval(this.myTimer, 1000);
      this.getPasswordButtonShow = true;
      StartClock_n(this.getPasswordButtonShowFunction, this.n);
      //SMSVerificationCode
      await 获取验证码("/SMSVerificationCode", this.username);
    },
    myTimer() {
      this.n = this.n - 1;
      this.获取验证码 = "重发动态密码(" + String(this.n) + ")";
    },
    getPasswordButtonShowFunction() {
      this.getPasswordButtonShow = false;
      clearInterval(this.计时器);
      this.获取验证码 = "获取动态密码";
      this.n = k;
    },
    loginStart() {
      if (this.登录方式 == false) {//手机号登录
        if (this.username == null) {
          systemErrorMsg("手机号为null");
        } else {
          if (this.username == "") {
            systemErrorMsg("手机号为空值");
          } else {
            if (this.password == null) {
              systemErrorMsg("动态密码为null");
            } else {
              if (this.password == "") {
                systemErrorMsg("动态密码为空值");
              } else {
                if (this.图形验证码 == null) {
                  systemErrorMsg("验证码为null");
                } else {
                  if (this.图形验证码 == "") {
                    systemErrorMsg("验证码为空值");
                  } else {
                    //console.log("loginRSA登录")
                    this.loginRSA();
                  }
                }
              }
            }
          }
        }
      } else {//用户名登录
        if (this.username == null) {
          systemErrorMsg("用户名为null");
        } else {
          if (this.username == "") {
            systemErrorMsg("用户名为空值");
          } else {
            if (this.password == null) {
              systemErrorMsg("密码为null");
            } else {
              if (this.password == "") {
                systemErrorMsg("密码为空值");
              } else {
                this.login();
              }
            }
          }
        }
      }
    },
    login: function () {
      let data = {"username": this.username, "password": this.password};

      axios.defaults.baseURL = AppConfig.登录服务器地址;//登陆使用登录服务器
      axios({
        method: "post",
        url: "/login",
        data: qs.stringify(data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
        }
      })
          .then(
              function (response) {
                //this.rst = response;
                ////console.log(response);
                ////console.log(response.data.flag);
                ////console.log(response.data.msg);
                sessionStorage.clear();
                if (response.data.flag == true) {//登录成功
                  let obj = JSON.parse(response.data.msg);
                  let RSAPrivateKey2String = obj.RSAPrivateKey2String;
                  let RSAPublicKey1String = obj.RSAPublicKey1String;
                  let token = obj.token;
                  ////console.log("RSAPrivateKey2String=\n"+RSAPrivateKey2String);
                  ////console.log("RSAPublicKey1String=\n"+RSAPublicKey1String);
                  ////console.log("token=\n"+token);
                  sessionStorage.setItem("RSAPrivateKey2String", RSAPrivateKey2String);
                  sessionStorage.setItem("RSAPublicKey1String", RSAPublicKey1String);
                  sessionStorage.setItem("token", token);
                  sessionStorage.setItem("islogin", "ok");
                  sessionStorage.setItem("jobnumber", obj.jobnumber);
                  sessionStorage.setItem("name", obj.name);
                  sessionStorage.setItem("department", obj.department);
                  sessionStorage.setItem("limit", obj.limit);
                  sessionStorage.setItem("limitCollective", obj.limitCollective);
                  axios.defaults.baseURL = AppConfig.具体服务所在服务器地址;//登陆完，切换回来
                  router.push(AppConfig.公共基础路径 + "home/index");
                } else {
                  document.getElementById("rst").innerHTML = response.data.msg;
                  systemErrorMsg('登录失败' + response.data.msg);
                }
              })
          .catch(function (error) {
            console.log(error);
          })
    },
    prelogin: function () {
      let data = {"us": "password"};
      axios.defaults.baseURL = AppConfig.登录服务器地址;//登陆使用登录服务器
      axios({
        method: "post",
        url: "/keyurl",
        data: qs.stringify(data),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Requested-With': 'XMLHttpRequest',
        }
      })
          .then(
              function (response) {
                //this.rst = response;
                ////console.log(response);
                ////console.log(response.data.flag);
                ////console.log(response.data.msg);
                sessionStorage.clear();
                if (response.data.flag == true) {//登录成功
                  let RSAPublicKey1String = response.data.msg;
                  sessionStorage.setItem("RSAPublicKey1String", RSAPublicKey1String);
                } else {
                  document.getElementById("rst").innerHTML = response.data.msg;
                  systemErrorMsg('登录失败' + response.data.msg);
                }
              })
          .catch(function (error) {
            console.log(error);
          })
    },
    async loginRSA() {
      await this.prelogin();
      let rsapks = sessionStorage.getItem('RSAPublicKey1String');
      if (rsapks != null) {
        if (rsapks != "") {
          if (rsapks.length > 0) {
            let dataObject = {
              username: this.username,
              password: this.password,
              picpw: this.图形验证码,
            };
            请求实体_参数加密.init(dataObject);
            //await requestLogin("/loginRSA",RequestEntity.postQSdata);
            let data = 请求实体_参数加密.postQSdata;
            let aa = {}
            for (let key in data) {
              aa[key] = data[key];
              ////console.log(aa[key]);
            }
            aa["key"] = rsapks;
            ////console.log(aa["key"]);
            axios({
              method: "post",
              url: "/loginRSA",
              data: qs.stringify(aa),
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'X-Requested-With': 'XMLHttpRequest',
              }
            })
                .then(
                    function (response) {
                      //this.rst = response;
                      ////console.log(response);
                      ////console.log(response.data.flag);
                      ////console.log(response.data.msg);
                      sessionStorage.clear();
                      if (response.data.flag == true) {//登录成功
                        let obj = JSON.parse(response.data.msg);
                        let RSAPrivateKey2String = obj.RSAPrivateKey2String;
                        let RSAPublicKey1String = obj.RSAPublicKey1String;
                        let token = obj.token;
                        ////console.log("RSAPrivateKey2String=\n"+RSAPrivateKey2String);
                        ////console.log("RSAPublicKey1String=\n"+RSAPublicKey1String);
                        ////console.log("token=\n"+token);
                        sessionStorage.setItem("RSAPrivateKey2String", RSAPrivateKey2String);
                        sessionStorage.setItem("RSAPublicKey1String", RSAPublicKey1String);
                        sessionStorage.setItem("token", token);
                        sessionStorage.setItem("islogin", "ok");
                        sessionStorage.setItem("jobnumber", obj.jobnumber);
                        sessionStorage.setItem("name", obj.name);
                        sessionStorage.setItem("department", obj.department);
                        sessionStorage.setItem("limit", obj.limit);
                        sessionStorage.setItem("limitCollective", obj.limitCollective);
                        axios.defaults.baseURL = AppConfig.具体服务所在服务器地址;//登陆完，切换回来
                        router.push(AppConfig.公共基础路径 + "home/index");
                      } else {
                        document.getElementById("rst").innerHTML = response.data.msg;
                        systemErrorMsg('登录失败' + response.data.msg);
                      }
                    })
                .catch(function (error) {
                  console.log(error);
                });
          }
        }
      }
    },
  }
  ,
  async mounted() {
    await this.refreshCode();
    // this.username="mlzhang";
    // this.password="@asia0017";
    // this.login();
  }
}


function gettime() {
  let date = new Date();
  let year = date.getFullYear();
  return year;
}

async function 获取验证码(method, username) {
  let data = {username: username};
  axios.defaults.baseURL = AppConfig.登录服务器地址;//登陆使用登录服务器
  axios({
    method: "post",
    url: method,
    data: qs.stringify(data),
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
    }
  })
      .then(
          function (response) {
            if (response.data.flag == true) {//登录成功
              systemSuccessMsg(response.data.msg);
            } else {
              systemErrorMsg(response.data.msg);
            }
          })
      .catch(function (error) {
        console.log(error);
      })
}
</script>

<style scoped>
.myArco-row {
  /*background-color: #f00;*/
  width: 100vw !important;
  height: 100vh !important;
  background-image: url("../assets/backgroundconnotation.jpg");
  background-size: 100% 100%;
}
.myArco-col {
  /*background-color: #ff6600;*/
  /*color: var(--color-white);*/
  text-align: center;
}
.myArcoColLogin{
  background-color: var(--color-neutral-2);
  opacity: 0.7;
}
.loginrow1{
    padding: 2px 14px 2px 14px;
}
/*
在桌面端(这里不涉及移动端)，浏览器的可视区域也被称为“视口“，而它的宽度与高度也有相应的单位来表示，分别为”vw”与”vh”。

vw：表示视口的宽度，1vw表示视口宽度的 1%;

vh：表示视口的高度，1vh表示视口高度的 1%;

示例1：

定义一个元素，其宽度与高度和浏览器可视区域相同！

        div{
            width:50vw;
            height: 50vh;
            background-color: antiquewhite;
            text-align: center;
        }

1、除了”vw”与”vh”以外，视口单位还包括”vmin”与”vmax”.

2、vmin：选取vw和vh中最小的那个

3、vmax：选取vw和vh中最大的那个
 */
</style>
