<template>
  <a-affix :offset-bottom="1">
    <div :style="{ padding: '4px' }">
      <a-space >
        <a-tag>&copy; 2021-{{time}}  {{ x版权单位 }}</a-tag>
      </a-space>
    </div>
  </a-affix>
</template>

<script>
import AppConfig from "@/AppConfig";

export default {
  name: "footVue",
  data(){
    return{
      time:gettime(),
      x版权单位:AppConfig.页面底部版权单位名称,
    }
  }
}

function gettime(){
  let  date =  new  Date();
  let  year = date.getFullYear();
  return year;
}
</script>

<style scoped>

</style>
