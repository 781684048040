<template>
  <LoadingVue :loading="loading1"/>
  <GetDataError :flag="getDataErrorShow"/>
  <a-row justify="center" align="center">
    <a-col :span="22">
<!--      <template v-for="(item,index) in systemList" :key="index">-->
<!--        <a-space size="medium" align="start" wrap>-->
<!--          <systemInfoCard :systemInfo="item"/>-->
<!--        </a-space>-->
<!--      </template>-->

      <a-grid :cols="{ xs: 1, sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 }" :colGap="12" :rowGap="16" class="grid-demo-grid">
        <template v-for="(item,index) in systemList" :key="index">
          <a-grid-item class="demo-item">
            <systemInfoCard :systemInfo="item"/>
          </a-grid-item>
        </template>
      </a-grid>
    </a-col>
  </a-row>

</template>

<script>
import {systemErrorMsg} from "@/js/myNotification";
import { 响应接收_响应加密} from "@/request/响应接收";
import { 请求_参数加密_响应加密} from "@/request/请求处理";
import systemInfoCard from "@/views/Index/systemInfoCard";
import LoadingVue from "@/components/global/LoadingVue.vue";
import GetDataError from "@/components/GetDataError.vue";

export default {
  name: "IndexVue",
  components: {systemInfoCard,LoadingVue,GetDataError},
  data() {
    return {
      systemList: [],
      loading1: false,
      getDataErrorShow: false,
    };
  },
  async created() {
    this.getDataErrorShow = false;
    this.loading1 = true;
    //let loading = this.$loading({text:"返回数据库名称集合，加载中..."});
    let table = await 返回可用数据库信息();
    if (table == null) {
      this.getDataErrorShow = true;
      this.loading1 = false;
    }else{
      if (table.length > 0) {
        this.systemList = [];
        let obj;
        for (let i = 0; i < table.length; i++) {
          obj = table[i];
          if (obj["是否显示"] == "是") {
            let xx = new systemInfo();
            let flag;
            for (let xxkey in xx) {
              flag = false;
              for (let objkey in obj) {
                if (xxkey == objkey) {
                  flag = true;
                  break;
                }
              }
              if (flag == true) {
                xx[xxkey] = obj[xxkey];
              }
            }
            this.systemList.push(xx);
          }
        }
      }
      this.loading1 = false;
    }
  },
}

function systemInfo() {
  this.id = "";
  this.系统名称 = "";
  this.系统子名称 = "";
  this.状态 = "";
  this.是否显示 = "";
  this.是否可用 = "";
  this.数据库名 = "";
  this.数据频度 = "";
  this.上级主管部门 = "";
  this.内部业务负责部门 = "";
  this.工作时间点 = "";
  this.跳转方法地址 = "";
  this.跳转参数名数组 = "";
  this.跳转参数值数组 = "";
}

async function 返回可用数据库信息() {
  let da = {
    dbn: 'a'
  }
  await 请求_参数加密_响应加密("/SystemList", da);
  //console.log(axios.defaults.baseURL);
  if(响应接收_响应加密.msg == null){
    systemErrorMsg("获取可用数据库信息失败。");
    return null;
  }
  let aa = JSON.parse(响应接收_响应加密.msg);
  // //console.log(aa);
  if (响应接收_响应加密.flag == true) {
    // console.log(aa)
    return aa.table;
  } else {
    systemErrorMsg("获取可用数据库信息失败。");
    return [];
  }
}
</script>

<style scoped>
.grid-demo-grid .demo-item{
    /*text-align: center;*/
}
</style>
